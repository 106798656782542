html {
  box-sizing: border-box;
}

body {
  margin: 0; 
  padding: 0;
  background: #eceff1;
  color: #444;
  font-family: 'Nunito Sans', sans-serif;
  font-weight: 400;
  font-size: 18px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a { text-decoration:none; color:black; }

a:hover {  }

p { user-select: none; }

h1 {
  font-family: 'BioRhyme', serif;
  font-weight: 300;
  font-size: 32px;
  margin: 0;
  text-align: left;
}

h2 {
  font-weight: 500;
  font-size: 34px;
  margin: 10px 0;
  text-align: left;
  user-select: none;
}

h3 {
  font-weight: 400;
  margin: 30px 0 0 0;
  text-align: left;
  font-size: 22px;
  font-weight: 700;
}

label {
  display: block;
  margin-top: 10px;
  font-size: 16px;
  user-select: none;
}

input, textarea, select, option {
  font-family: 'BioRhyme', serif;
  font-size: 20px ;
  display: block;
  outline:none; 
  border:1px solid #888; 
  border-radius:4px;
  padding: 8px 12px;
  width: 100%;
  box-sizing:border-box;
}

input:focus, textarea:focus, select:focus {
  -webkit-box-shadow: 0px 0px 3px #0091ea;
  box-shadow: 0px 0px 3px #0091ea;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  opacity: 0.5; /* Firefox */
}

button {
  background-color: #f45a0f;
  color: #f6f1eb;
  padding:10px 30px;
  border: 2px solid #f45a0f;
  border-radius:23px ;
  font-weight: 500;
  font-size: 20px;
  user-select: none;
}

.bioRhyme {
  font-family: 'BioRhyme', serif;
}

.nunito {
  font-family: 'Nunito Sans', sans-serif;
}

.centered {
  flex-grow: 1;
  padding: 0;
  width: 100%;
  max-width: 480px;
  margin: auto;
}

header {
  display: flex;
  padding: 10px 10px;
  background-color: #dedad6;
  user-select: none;
}

main {
  display: flex;
  padding: 0;
}

footer {
  display: flex;
  font-size: 22px;
  color: #f6f1eb;
  padding: 0 10px;
  background-color: #212121;
  min-height: 400px;
  user-select: none;
}